<template>
  <div class="auth-area frmregister" id="register" v-cloak>
    <div class="login-area">
      <form @submit.prevent="createAccount" novalidate>
        <div class="register-form" style="width: 559.64px;">
          <auth-form-progress v-if="request_send"></auth-form-progress>

          <div class="w-100 register-body" style="display: block;">
            <div class="register-left-side">
              <auth-form-header :login="false" class="register-form-header">
                <template v-slot:header-label>Create your {{ $store.state.siteName }} Account</template>
              </auth-form-header>

              <div class="register-form-body">
                <div class="register-control-padding-r">
                  <div class="main">
                    <div class="register-control-left">
                        <label class="control-label" for="auth-first-name">First Name</label>

                        <input
                          :class="{'has-error': (errorsForm.firstNameRequired)}"
                          class="form-control"
                          id="auth-first-name"
                          placeholder="Enter first name"
                          type="text"
                          v-model="credentials.first_name"
                        />
                    </div>
                    <div v-if="isMobile">
                        <label class="text-danger small" v-if="errorsForm.firstNameRequired">
                          <img alt class="warning-icon" src="../../assets/img/auth/warning.svg" />
                          {{ errorsForm.first_name }}
                        </label>
                        <label class="text-danger small" v-if="errorsForm.nameInvalid">
                          <img alt class="warning-icon" src="../../assets/img/auth/warning.svg" />
                          {{ errorsForm.name }}
                        </label>
                    </div>
                    <div v-else>
                      <div
                        class="register-control-warning"
                        v-if="errorsForm.firstNameRequired || errorsForm.nameInvalid"
                        >
                        <label
                          class="text-danger small text-danger-register-left"
                          v-if="errorsForm.firstNameRequired"
                          >
                          <img alt class="warning-icon" src="../../assets/img/auth/warning.svg" />
                          {{ errorsForm.first_name }}
                        </label>
                        <label class="text-danger small" v-if="errorsForm.nameInvalid">
                          <img alt class="warning-icon" src="../../assets/img/auth/warning.svg" />
                          {{ errorsForm.name }}
                        </label>
                      </div>
                    </div>
                  </div>

                  <div class="register-control-right">
                    <label class="control-label" for="auth-last-name">Last Name</label>

                    <input
                      :class="{'has-error': (credentials.last_name !== '' && errorsForm.nameInvalid)}"
                      class="form-control"
                      id="auth-last-name"
                      placeholder="Enter last name"
                      type="text"
                      v-model="credentials.last_name"
                    />
                  </div>
                  
                </div>

                <auth-email-field
                  :credentials="credentials"
                  :errors-form="errorsForm"
                  class="register-control"
                ></auth-email-field>
                <div class="mt-5 register-control">
                  <label class="control-label" for="auth-last-name">MBSM Code</label>
                  <input
                      class="form-control"
                      id="login-email"
                      placeholder="Enter code"
                      type="text"
                      v-model="credentials.mbsm_code"
                    />
                </div>

                <new-password
                  :credentials="credentials"
                  :errors-form="errorsForm"
                  class="register-control"
                ></new-password>

                <div class="row login-body-footer">
                  <!-- NEMESIS CHANGE <div class="form-group row register-control-padding-r"> -->
                  <div class="col-xs-6 create-account-link">
                    <router-link :disabled="request_send" to="/login/1">Sign in instead</router-link>
                  </div>

                  <div class="col-xs-6 text-right">
                    <button
                      :disabled="request_send"
                      @click="createAccount"
                      class="btn btn-primary"
                      type="submit"
                    >Get Started</button>
                  </div>
                  <div class="social-login form-group m-top-3" v-if='isMobile'>
                    <p class="s-text pt-15">
                      <b>or Sign up with</b>
                    </p>
                    <a href="javascript:void(0)" style="margin-right:30px;" class="google-login">
                      <GoogleLogin :params="params" :onSuccess="onSuccess" :onFailure="onFailure">
                        <img src="../../assets/img/social/google.jpg" alt style="width:47px" />
                      </GoogleLogin>
                    </a>
                    <a href="javascript:void(0)">
                      <img
                        src="../../assets/img/social/fb.png"
                        @click="logInWithFacebook"
                        alt
                        style="width:50px"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="signup-social">
              <div class="social-login form-group m-top-3" v-if='!isMobile'>
                <p class="s-text">
                  <b>or Sign up with</b>
                </p>
                <a href="javascript:void(0)" style="margin-right:30px;" class="google-login">
                  <GoogleLogin :params="params" :onSuccess="onSuccess" :onFailure="onFailure">
                    <img src="../../assets/img/social/google.jpg" alt style="width:47px" />
                  </GoogleLogin>
                </a>
                <a href="javascript:void(0)">
                  <img
                    src="../../assets/img/social/fb.png"
                    @click="logInWithFacebook"
                    alt
                    style="width:50px"
                  />
                </a>

              </div>
            </div> -->
            <!-- <register-right></register-right> -->
          </div>
        </div>

        <support-link></support-link>
      </form>
    </div>

    <auth-footer></auth-footer>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import AuthFooter from "./Items/AuthFooter";
import SupportLink from "./Items/SupportLink";
import RegisterRight from "./Items/RegisterRight";
import AuthFormProgress from "./Items/AuthFormProgress";
import AuthFormHeader from "./Items/AuthFormHeader";
import AuthEmailField from "./Items/AuthEmailField";
import NewPassword from "./Items/NewPassword";
import MomentTimezone from "moment-timezone";
import GoogleLogin from "vue-google-login";
export default {
  name: "Register",
  components: {
    NewPassword,
    AuthEmailField,
    AuthFormHeader,
    AuthFormProgress,
    AuthFooter,
    SupportLink,
    RegisterRight,
    MomentTimezone,
    GoogleLogin
  },
  data: () => ({
    credentials: {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      password_confirmation: "",
      isMobile: false,
      timezone: "",
      refUserId: null,
      linkId: null,
      register_page: 'main',
      mbsm_code: ''
    },
    request_send: true,
    errorsForm: {
      first_name: "First name is required.",
      name: "Are you sure you entered your name correctly?",
      invalidEmail: "Invalid Email Address.",
      requireEmail: "Email is required",
      email:
        "This email is already registered. Please try a different email address.",
      password:
        "Use 8 characters or more with a mix of letters, numbers & symbols.",
      password_confirmation: "Password does not match.",
      firstNameRequired: false,
      nameInvalid: false,
      emailInvalid: false,
      emailExist: false,
      bemailEmpty: false,
      passwordInvalid: false,
      passwordConfirmInvalid: false,
      passwordType: {
        uppercase: false,
        lowercase: false,
        number: false,
        special: false,
        minimum: false
      }
    },
    showPasswordText: false,
    params: {
      client_id: process.env.googleId
    }
  }),
  mounted() {
    this.request_send = false;
    this.credentials.timezone = MomentTimezone.tz.guess();
    this.loadFacebookSDK(document, "script", "facebook-jssdk");
    this.initFacebook();
    setTimeout(function() {
      document
        .querySelectorAll(".google-login button")[0]
        .setAttribute("type", "button");
    }, 500);
  },
  created() {
    this.mobileCheck();
    localStorage.setItem("register_gate_mailchimptags", []);

    this.credentials.refUserId = this.getCookie('ref')
    this.credentials.linkId = this.getCookie('lid')

  },
  methods: {
    getCookie(cname) {
      var name = cname + "=";
      var decodedCookie = decodeURIComponent(document.cookie);
      var ca = decodedCookie.split(';');
      for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
    ...mapMutations({
      setAccessToken: "setAccessToken",
      setUserInfo: "setUserInfo",
      destroyAccessToken: "destroyAccessToken"
    }),

    ...mapActions({
      registerUser: "registerUser",
      getPlans: "getPlans"
    }),

    createAccount() {
      this.clearValidate();

      let valid = true;

      if (
        this.credentials.first_name === "" ||
        this.credentials.first_name === null ||
        this.credentials.first_name === " "
      ) {
        this.errorsForm.firstNameRequired = true;
        valid = false;
      } else {
        this.errorsForm.firstNameRequired = false;
        if (!this.validateName(this.credentials.first_name)) {
          this.errorsForm.nameInvalid = true;
          valid = false;
        }

        if (this.credentials.last_name !== "") {
          if (!this.validateName(this.credentials.last_name)) {
            this.errorsForm.nameInvalid = true;
            valid = false;
          }
        }
      }
      if (
        this.credentials.email === "" ||
        this.credentials.email === null ||
        this.credentials.email === " "
      ) {
        this.errorsForm.bemailEmpty = true;
      } else {
        this.errorsForm.bemailEmpty = false;
        if (!this.validateEmail()) {
          this.errorsForm.emailInvalid = true;
          valid = false;
        }
      }

      if (!this.validatePassword()) {
        this.errorsForm.passwordInvalid = true;
        valid = false;
      }

      if (
        this.credentials.password !== this.credentials.password_confirmation
      ) {
        this.errorsForm.passwordConfirmInvalid = true;
        valid = false;
      }

      if (!valid) {
        return false;
      }

      this.request_send = true;

      this.registerUser({ ...this.credentials })
        .then(response => {
          this.request_send = false;

          if (response.data.result === "success") {
            this.setUserInfo(response.data.userInfo);
            window.BCookie.set("BC-Auth-Email", response.data.email, 259200);


            const {
              access_token: accessToken,
              expires_in: tokenExpired
            } = response.data;

            this.setAccessToken({
              token: accessToken,
              expires: tokenExpired
            });
            let first_name = (response.data.userInfo)?response.data.userInfo.first_name:'';
            let last_name = (response.data.userInfo)?response.data.userInfo.last_name:'';
            // this.setRegisterTags(response.data.email,first_name,last_name);
            const redirectUri =  "/";
            window.Ls.remove("BC-Auth-Remember");
            window.Ls.remove("sub-user-info");
            this.$router.push(redirectUri);
            this.$forceUpdate();
            // this.getPlans().then(() => {
            //   this.$router.push("/subscription");
            //   this.$forceUpdate();
            // });
          } else {
            if (response.data.result == 'blocked') {
              this.errorsForm.emailExist = true;
              this.errorsForm.email = 'This email is not allowed to sign up.';
            } else {
              this.errorsForm.email = 'This email is already registered. Please try a different email address.';
              this.errorsForm.emailExist = true;
              this.errorsForm.nameInvalid = true;
              this.errorsForm.passwordInvalid = true;
            }
            this.destroyAccessToken();
          }
        })
        .catch(error => {
          this.request_send = false;
          console.log(error)
          if (error.response.status === 422) {
            if (error.response.data.errors) {
              if (error.response.data.errors.email) {
                this.errorsForm.emailExist = true;
              }
            }
          }

          this.destroyAccessToken();
        });
    },

    clearValidate() {
      this.errorsForm.firstNameRequired = false;
      this.errorsForm.nameInvalid = false;
      this.errorsForm.emailInvalid = false;
      this.errorsForm.emailExist = false;
      this.errorsForm.passwordInvalid = false;
      this.errorsForm.passwordConfirmInvalid = false;
      this.errorsForm.passwordType = {
        uppercase: false,
        lowercase: false,
        number: false,
        special: false,
        minimum: false
      };
    },

    validateName(name) {
      let re = /^[a-zA-Z]+$/;
      return re.test(String(name).toLowerCase());
    },

    validatePassword() {
      window.vEvent.fire("password-validation", this.credentials.password);

      let re = /^(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]{8,}$/;
      return re.test(String(this.credentials.password));
    },

    /**
     * Email validation check.
     * @returns {boolean}
     */
    validateEmail() {
      let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(this.credentials.email).toLowerCase());
    },

    mobileCheck() {
      let BC_onMobile = false;
      if (
        window.screen.availWidth <= 414 ||
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        BC_onMobile = true;
      }

      this.isMobile = BC_onMobile;
    },
    /**/
    async logInWithFacebook() {
      window.FB.login(
        response => {
          if (response.authResponse) {
            let loginData = {
              id: response.authResponse.userID,
              type: "facebook"
            };
            window.FB.api(
              "/me",
              { fields: "id,name,email,link,gender,locale,picture" },
              user => {
                console.log(user);
                loginData["name"] = user.name;
                const picture = user.picture;
                const pictureDate =
                  typeof picture.data !== "undefined" ? picture.data : "";

                loginData["photo_url"] =
                  typeof pictureDate.url !== "undefined" ? pictureDate.url : "";
                loginData["email"] =
                  typeof user.email !== "undefined" ? user.email : "";
                this.socialRegister(loginData);
              }
            );
          } else {
          }
        },
        { scope: "email,user_likes", return_scopes: true }
      );
      return false;
    },
    async initFacebook() {
      window.fbAsyncInit = function() {
        window.FB.init({
          // appId: process.env.facebookId, //You will need to change this
          appId: process.env.facebookId, //You will need to change this
          cookie: true, // This is important, it's not enabled by default
          version: "v2.10",
          xfbml: true
        });
      };
    },
    async loadFacebookSDK(d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    },
    socialRegister(data) {
      this.axios({
        method: "post",
        url: "/social_register",
        params: data
      })
        .then(response => {
          let data = response.data;
          if (data.hasOwnProperty("type") && data.type == "error") {
            alert("Already register please login");
          } else {
            this.$store.commit("setAccessToken", {
              token: data.access_token,
              expires: data.expires_in
            });
            this.$nextTick(() => {
              this.$store.dispatch("getUserData").then(re => {
                if (re.data.user_status === 1) {
                  if (
                    re.data.billing_status === "Inactive" ||
                    re.data.billing_status === "Expired"
                  ) {
                    this.$router.push("/subscription");
                    this.$forceUpdate();
                  } else if (
                    re.data.billing_status === "Cancelled" ||
                    re.data.billing_status === "Failed"
                  ) {
                    window.BCookie.remove("BC-Auth-Email");
                    this.errorsForm.accountCancelled = true;
                    this.destroyAccessToken();
                    this.goFirstStep();
                  } else if (re.data.billing_status === "VerifyRequired") {
                    window.BCookie.remove("BC-Auth-Email");
                    this.errorsForm.accountNotVerified = true;
                    this.errorsForm.email =
                      "This account is not verified, please check your email.";
                    this.userId = re.data.id;
                    this.destroyAccessToken();
                    this.goFirstStep();
                  } else {
                    const redirectUri = window.BCookie.check("redirect_path")
                      ? window.BCookie.get("redirect_path")
                      : "/";

                    window.Ls.remove("BC-Auth-Remember");
                    window.Ls.remove("sub-user-info");
                    this.$router.push(redirectUri);
                    this.$forceUpdate();
                  }
                } else {
                  window.BCookie.remove("BC-Auth-Email");
                  this.errorsForm.accountCancelled = true;
                  this.destroyAccessToken();
                  this.goFirstStep();
                }
              });
            });
            let first_name = (response.data.userInfo)?response.data.userInfo.last_name:'';
            let last_name = (response.data.userInfo)?response.data.userInfo.last_name:'';
            this.setRegisterTags(data.email,response.data.userInfo.first_name,response.data.userInfo.last_name);
          }
        })
        .catch(function(response) {
          console.log(response.message);
        });
    },
    onSuccess(googleUser) {
      console.log(googleUser);
      let user = googleUser.getBasicProfile();
      console.log('ID: ' + user.getId());
      let loginData = {};
      // loginData["name"] = user.Bd;
      // loginData["email"] = user.Du;
      // loginData["id"] = user.PU;
      // loginData["photo_url"] = user.SK;
      loginData["type"] = "gmail";
      loginData["name"] = user.getName();
      loginData["email"] = user.getEmail();
      loginData["id"] = user.getId();
      loginData["photo_url"] = user.getImageUrl();
      this.socialRegister(loginData);
    },
    onFailure(googleUser) {
      console.log(googleUser);

      // This only gets the user information: id, name, imageUrl and email
      //console.log(googleUser.getBasicProfile());
    },
    sendTags(email,first_name,last_name) {
      let tags = localStorage.getItem("register_gate_mailchimptags");
      tags = tags && tags != "" ? JSON.parse(tags) : [];
      this.axios({
        method: "post",
        url: "/subscription_tags",
        params: { tags: JSON.stringify(tags), email:email,first_name:first_name,last_name:last_name },
      }).then((response) => {
        localStorage.setItem("register_gate_mailchimptags", []);
      });
    },
    setRegisterTags(email,first_name,last_name){
    this.setMailChimTag('initiated signup')
    this.setMailChimTag('adilo main register optin')
    this.setMailChimTag('created account')
    this.sendTags(email,first_name,last_name);
  },
  setMailChimTag(tag) {
    var tags = localStorage.getItem("register_gate_mailchimptags");
    if (tags) {
      tags = JSON.parse(tags);
      if (jQuery.inArray(tag, tags) === -1) tags.push(tag);
    } else {
      var tags = [];
      tags.push(tag);
    }
    tags = JSON.stringify(tags);
    localStorage.setItem("register_gate_mailchimptags", tags);
  }
  },
  
};
</script>
<style>
.auth-area{
  width: 100% !important;
}
</style>
<style lang="less" src="../../assets/less/custom/pages.less"></style>
<style lang="less" src="../../assets/less/custom/auth.less"></style>
